<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                  </el-row>
                </el-col>
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterProductSellsModal()"
                ></el-button>
                <!--<el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>-->
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Sells.data"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="op" label="operador">
        <template #default="c">
          {{ c?.row?.op_name }}
        </template>
      </el-table-column>
      <el-table-column label="data" :formatter="(r) => formatDate(r.sold_in)">
      </el-table-column>
      <el-table-column prop="product_name" label="produto"> </el-table-column>
      <el-table-column
        label="quantidade"
        prop="quantity"
        :formatter="(r) => numberFormatter.format(Number(r.quantity) || 0)"
      ></el-table-column>
      <el-table-column
        label="valor"
        prop="amount"
        :formatter="(r) => currencyFormatter.format(Number(r.amount) || 0)"
      ></el-table-column>
      <el-table-column
        label="valor total"
        prop="total_amount"
        :formatter="
          (r) => currencyFormatter.format(Number(r.total_amount) || 0)
        "
      ></el-table-column>
      <el-table-column label="referência" min-width="200">
        <template #default="s">
          <el-select
            v-model="s.row.product.uid"
            value-key="uid"
            filterable
            clearable
            remote
            reserve-keyword
            placeholder="Vincular um produto"
            @change="(v) => selectProduct(s.row, v)"
            :remote-method="
              (searchName) => fetchProducts(searchName, s?.row?.uid)
            "
          >
            <el-option
              v-for="item in mountedProducts(s?.row?.uid)"
              :key="item?.uid"
              :label="item?.product?.name"
              :value="item?.uid"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Sells?.lastPage"
    >
    </el-pagination>
    <filter-product-sells-modal
      :showModal="showFilterSellsModal"
      @close-modal="showFilterSellsModal = false"
      @update-filters="updateFilters"
    ></filter-product-sells-modal>
  </el-card>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import FilterProductSellsModal from "./modals/FilterProductSellsModal.vue";
//import { uuid } from "vue-uuid";

export default {
  name: "Sells",
  components: {
    FilterProductSellsModal,
  },
  data: () => ({
    showFilterSellsModal: false,
    sells: null,
    products: {},
    currentPage: 1,
    cashier: null,
    filterOptions: {},
    isLoadingDownloadButton: false,
    isLoading: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchSells();
    this.fetchProducts(" ", "all");
  },
  computed: {
    Sells() {
      return this.sells || {};
    },
    ShowProducts() {
      return this?.products || {};
    },
  },
  watch: {
    currentPage() {
      this.fetchSells();
    },
  },
  methods: {
    selectProduct(sell, mountedId) {
      const product = this.findProductByMountedId(mountedId, sell.uid);
      if (product) {
        sell.product_id = product.uid;
        sell.product_mounted_id = product?.mounted?.find(
          (m) => m.id === mountedId
        )?.id;
        this.updateSell(sell);
      }
    },
    findProductByMountedId(id, sellId) {
      return (this.ShowProducts[sellId] || this.ShowProducts["all"])?.find(
        (p) => p.mounted?.some((m) => id === m.id)
      );
    },
    mountedProducts(uid) {
      const products = this.ShowProducts[uid] || this.ShowProducts["all"];
      return (
        products
          ?.map((p) =>
            p?.mounted
              .map((m) => ({
                ...p,
                uid: m.id,
                product: {
                  name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
                    m.storage ?? ""
                  }`,
                },
              }))
              ?.flat()
          )
          ?.flat() || []
      );
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}Sells/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Fechamento de Caixa - ${
            this.filterOptions.firm_id || ""
          }.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .finally(() => this.changeDownloadButtonStatus());
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterProductSellsModal() {
      this.showFilterSellsModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchSells();
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchProducts(searchName, uid) {
      const url = new URL(`${this.$store.state.apiUrl}products`);
      url.search = new URLSearchParams({ searchName });
      if (searchName)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.products[uid] = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
    fetchSells() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}Sells`);
      url.search = new URLSearchParams({
        pagination: this.currentPage,
        hasReference: false,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.sells = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    updateSell(sell) {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}sells/${sell?.uid}`);
      if (sell)
        fetch(url, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(sell),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then(() => {
            this.fetchSells();
          })
          .catch(() => (this.hasError = true));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #909399;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
}
.summary-black {
  color: #333 !important;
}
</style>